import React, {useEffect} from 'react';
import '../App.css';
import MainService from "../services/Main.service";
import { useHistory } from "react-router-dom";
import {AppData} from "../helpers/App.data";
import {useAlert} from "react-alert";

export default function SignInPage() {
    const mainService = new MainService();
    const appData = new AppData();
    const history = useHistory();
    const alert = useAlert();


    let onSubmit = (event) =>{
         event.preventDefault();
         mainService.userLogin(event.target).then(async (res)=>{
             if(res.data && res.data.body && res.data.code != 401){
                 await appData.setUser({
                     token: res.data.body.token
                 });
                 history.push('/home');
             }else{
                 alert.show("Invalid username of password", {
                     timeout: 2000, // custom timeout just for this one alert
                     type: 'error',
                 })
             }
         }).catch((err)=>{
             alert.show("Invalid username of password", {
                 timeout: 2000, // custom timeout just for this one alert
                 type: 'error',
             })
         });

    }

    return (
        <div className="text-center m-5-auto">
            <h2>Sign In</h2>
            <form onSubmit={onSubmit}>
                <p>
                    <label>Username or email address</label><br/>
                    <input type="text" name="username" required />
                </p>
                <p>
                    <label>Password</label>
                    <br/>
                    <input type="password" name="password" required />
                </p>
                <p>
                    <button id="sub_btn" type="submit">Login</button>
                </p>
            </form>

        </div>
    )
}
