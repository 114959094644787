import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import LoginPage from './pages/LoginPage'
import HomePage from './pages/HomePage'
import ServersPage from './pages/ServersPage'
import DatabasesPage from './pages/DatabasesPage'

import './App.css'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import ServicePage from "./pages/ServicePage";

export default function App() {

    const options = {
        // you can also just use 'bottom center'
        position: positions.BOTTOM_CENTER,
        timeout: 5000,
        // you can also just use 'scale'
        transition: transitions.SCALE
    }


    return (
        <AlertProvider template={AlertTemplate} {...options}>
        <Router>
            <div>
                <Switch>
                    <Route exact  path="/" component={ LoginPage } />
                    <Route

                        path="/home" component={ HomePage } />
                    <Route path="/service" component={ ServicePage } />
                    <Route path="/servers" component={ ServersPage } />
                    <Route path="/databases" component={ DatabasesPage } />
                </Switch>
            </div>
        </Router>
        </AlertProvider>
    )
}



