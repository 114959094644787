

export class AppData {

    user = null;

    constructor() {
        if(localStorage.getItem('user')){
            this.user = JSON.parse(localStorage.getItem('user'));
        }
    }

    setUser(_user){
        this.user = _user;
        this.save();
    }

    getUser(){
        return this.user;
    }

    save(){
        localStorage.setItem('user',JSON.stringify(this.user));
    }

    clear(){
        localStorage.clear();
    }


}
