import React, {useEffect, useState} from 'react'
import MainService from "../services/Main.service";

import DataTable from 'react-data-table-component';
import {useAlert} from "react-alert";
import {AppData} from "../helpers/App.data";
import {useHistory} from "react-router-dom";

export default function EC2ServicePage() {

    const [list,setList] = useState([]);
    const [isLoading,setIsLoading] = useState(true);
    const mainService = new MainService();
    const alert = useAlert();
    let _interval = null;

    const appData = new AppData();
    const history = useHistory();

    useEffect(()=>{
        if(!appData.getUser()){
            history.push('/');
        }
    })




    const columnsDatabase = [
        {
            name: 'Id',
            selector: row => row.id,
        },
        {
            name: 'Reference',
            selector: row => row.reference,
        },
        {
            name: 'Service Name',
            selector: row => row.serviceName,
        },
        {
            name: 'Status',
            cell: tableProps => (
                <div className={`service-status-${tableProps.status}`}></div>
            )
        },
        {
            name: 'Actions',
            cell: tableProps => (
                <button
                    onClick={(e)=>{
                        e.preventDefault();
                        updateState(tableProps);
                    }}
                    className={"refresh-button"}>
                    <img
                        alt={"Status"}
                        className={`refresh `} src={require(tableProps.status > 0 || tableProps.status === -1 ?  "../assets/images/stop.png" : "../assets/images/start-button.png")} />
                    <small>&nbsp;&nbsp;{'Service EC2'}</small>
                </button>
            )
        },
    ];



    useEffect(()=>{
        getData();

        _interval = setInterval(()=>{
            getData();
        },15000)

    },[]);

    useEffect(() => {
        return () => {
            if(_interval){
                clearInterval(_interval);
            }
        };
    }, []);


    async function getData(){
        await getAllServices('service-ec2');
        setIsLoading(false);
    }

    const updateState = async (item) => {
        await mainService.updateState(item.type,item).then((res)=>{

            let counter = 0;
            if(res.data && res.data.message) {
                alert.show(res.data.message, {
                    timeout: 2000, // custom timeout just for this one alert
                    type: 'success',
                })
            }


            const interval = setInterval(()=>{
                if(counter < 4) {
                    getData();
                    counter++;
                }
                if(counter > 4) {
                    clearInterval(interval);
                }
            },5000)

        }).catch((err)=>{
            console.log(err);
            alert.show(err.message, {
                timeout: 2000, // custom timeout just for this one alert
                type: 'error',
            })
        });
    }

    const getAllServices = (type) => {
        mainService.getAllServices(type).then((res) => {
            if (res.data.body) {
                res.data.body.map((d, index) => {
                    d['id'] = index + 1;
                    d['type'] = type;
                })



                setList(res.data.body);

            }
        }).catch((err) => {
            console.log(err);
            alert.show(err.message, {
                timeout: 2000, // custom timeout just for this one alert
                type: 'error',
            })
        })
    }


    return (
        <>

            <div className="text-center p-16">

                <h1>Services EC2</h1>
                <div className={"services-wrapper card mt-4"}>
                    <DataTable
                        dense
                        pagination
                        columns={columnsDatabase} data={list} progressPending={isLoading} />
                </div>


            </div>
        </>

    )
}
