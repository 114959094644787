import {Link, useHistory} from "react-router-dom";
import React from "react";
import {AppData} from "../../helpers/App.data";


export const Header = () => {
    const history = useHistory();


    return  <header className={"home-header"}>

        <div className={"d-flex"}>
            <Link to="/home">
                <img
                    alt={"Logo"}
                    className={"home-header-logo"} src={"https://uae.sellanycar.com/asset/logo-en.svg"} />
            </Link>
        </div>


        <div>
             <span className={"ml-2 mr-2"}>
               <Link to="/service">
                Services
            </Link>
            </span>

            <span className={"ml-2 mr-2"}>
             <Link to="/databases">
                Databases
            </Link>
            </span>
            <span className={"ml-2 mr-2"}>
              <Link to="/servers">
                Servers
            </Link>
            </span>
            <span
                onClick={()=>{

                   new AppData().clear();
                   history.push('/');
                }}
                className={"ml-2 mr-2"}>
                Logout
            </span>



        </div>

    </header>;
}
