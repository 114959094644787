import React, {useEffect, useState} from 'react'
import MainService from "../services/Main.service";

import DataTable from 'react-data-table-component';
import {Header} from "../components/header";
import {useAlert} from "react-alert";
import EC2ServicePage from "./EC2ServicePage";
import {AppData} from "../helpers/App.data";
import {useHistory} from "react-router-dom";

export default function ServersPage() {

    const [list,setList] = useState([]);
    const [isLoading,setIsLoading] = useState(true);
    const mainService = new MainService();
    const alert = useAlert();
    let _interval = null;

    const appData = new AppData();
    const history = useHistory();

    useEffect(()=>{
        if(!appData.getUser()){
            history.push('/');
        }
    })


    const columnsServers = [
        {
            name: 'Id',
            selector: row => row.id,
        },
        {
            name: 'Server Name',
            selector: row => row.name,
        },
        {
            name: 'Public IP / Private IP ',
            cell: tableProps => (
                <div> {tableProps.ip ? tableProps.ip :  "N/A" }</div>
            )
        },
        {
            name: 'Status',
            cell: tableProps => (
                <div className={`service-status-${tableProps.status}`}></div>
            )
        },
        {
            name: 'Actions',
            cell: tableProps => (
                <button
                    onClick={(e)=>{
                        e.preventDefault();
                        updateState(tableProps);
                    }}
                    className={"refresh-button"}>
                    <img
                        alt={"Status"}
                        className={`refresh `} src={require(tableProps.status > 0 || tableProps.status === -1 ?  "../assets/images/stop.png" : "../assets/images/start-button.png")} />
                        <small>&nbsp;&nbsp;{'Server'}</small>
                </button>
            )
        },
    ];



    useEffect(()=>{

        getData();
        _interval =  setInterval(()=>{
             getData();
         },15000)

    },[]);

    useEffect(() => {
        return () => {
            if(_interval) {
                clearInterval(_interval);
            }
        };
    }, []);

    async function getData(){
        await getAllServices('server');
        setIsLoading(false);
    }

    const updateState = async (item) => {
        await mainService.updateServer(item.type,item.status === 0 ? 1 : 0).then((res)=>{

            if(res.data && res.data.message) {
                alert.show(res.data.message, {
                    timeout: 2000, // custom timeout just for this one alert
                    type: 'success',
                })
            }

            let counter = 0;

            const interval = setInterval(()=>{
                if(counter < 4) {
                    getData();
                    counter++;
                }
                if(counter > 4) {
                    clearInterval(interval);
                }
            },5000)

        }).catch((err)=>{
            console.log(err);
            alert.show(err.message, {
                timeout: 2000, // custom timeout just for this one alert
                type: 'error',
            })
        });
    }

    const getAllServices = (type) => {
        mainService.getAllServices(type).then((res) => {
            if (res.data.body.length) {

                res.data.body[0].servers.map((d, index) => {
                    d['id'] = index + 1;
                    d['status'] = res.data.body[0]['status'];
                    d['type'] = type;
                });
                let result = [];

                result.push({
                    id: res.data.body[0].servers[0]['id'],
                    status: res.data.body[0].servers[0]['status'],
                    type: res.data.body[0].servers[0]['type'],
                    name: res.data.body[0].servers[0]['Name'] + " | " + res.data.body[0].servers[1]['Name'],
                    ip: res.data.body[0].servers[0]['PublicIpAddress'] && res.data.body[0].servers[1]['PrivateIpAddress'] ?  res.data.body[0].servers[0]['PublicIpAddress'] + " | " + res.data.body[0].servers[1]['PrivateIpAddress']:  "N/A",
                })

                console.log(res.data.body[0].servers[0]);
                setList(result);

            }
        }).catch((err) => {
            console.log(err);
            alert.show(err.message, {
                timeout: 2000, // custom timeout just for this one alert
                type: 'error',
            })
        })
    }

    const isServerEnabled = (items) => {
        return items.filter((i)=> i.status).length > 0
    }


    return (
        <>
            <Header />

            <div className="text-center p-16">

                <h1>Servers</h1>
                <div className={"services-wrapper card mt-4"}>
                    <DataTable
                        dense
                        pagination
                        columns={columnsServers} data={list} progressPending={isLoading} />
                </div>


            </div>

            {isServerEnabled(list) ? <EC2ServicePage />: null}
        </>

    )
}
