import React, {useEffect} from 'react'

import {Header} from "../components/header";
import {AppData} from "../helpers/App.data";
import {useHistory} from "react-router-dom";

export default function HomePage() {

    const appData = new AppData();
    const history = useHistory();

    useEffect(()=>{
        if(!appData.getUser()){
            history.push('/');
        }
    })

    return (
        <>
            <Header />
            <div className="text-center p-16">
                <img className={"devops-image"} alt={"Devops"} src={require("../assets/images/Look-At-Me.jpg")} />
            </div>
        </>

    )
}
