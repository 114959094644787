import axios from 'axios';
import {AppConfig} from "../config";
import {AppData} from "../helpers/App.data";

export default class MainService {

    appData = new AppData();

    constructor() {
        this.instance = axios.create({
            baseURL: AppConfig.apiUrl,
            timeout: 3000
        });
    }

    async userLogin(data){
        return await this.instance.post('auth',data,{
            headers: {'content-Type': 'application/json'}
        });
    }

    async getAllServices(type){
        return await this.instance.get(type,{
            headers: {
                'sac-devops-auth': this.appData.getUser().token
            },
            params: {
                "sac-devops-auth":  this.appData.getUser().token
            }
        });
    }

    async updateState(type,item){

        delete item['id'];
        delete item['isActive'];
        delete item['statusImage'];

        if(item['status'] || item['status'] === -1){
            item['status'] = 0;
        }else{
            item['status'] = 1;
        }


        return await this.instance.put(type,[item],{
            headers: {
                'sac-devops-auth': this.appData.getUser().token
            },
            params: {
                "sac-devops-auth":  this.appData.getUser().token
            }
        });
    }


    async updateServer(type,status){


        return await this.instance.put(type,[{
            status
        }],{
            headers: {
                'sac-devops-auth': this.appData.getUser().token
            },
            params: {
                "sac-devops-auth":  this.appData.getUser().token
            }
        });
    }




}
